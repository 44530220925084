import React from 'react';
import { Link } from '@mui/material';
import { WHATS_APP_LINK } from '../../constants/links';
import LinkedInIcon from '../icons/LinkedInIcon';

const LinkedInLink = () => (
  <Link
    aria-label="LinkedIn blank link"
    href={WHATS_APP_LINK}
    target="_blank"
    rel="noopener noreferrer"
  >
    <LinkedInIcon />
  </Link>
);

export default LinkedInLink;
