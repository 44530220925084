import React from 'react';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

const SimulatorRegisterVideoLandingTitle = () => {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('tablet'));
  return (
    <>
      <Typography
        color="#000"
        fontSize={{ mobile: 25, desktop: 28 }}
        textAlign={{ mobile: 'center', desktop: 'left' }}
        fontWeight={500}
        lineHeight="120%"
        width={{ mobile: '90%', desktop: '80%' }}
      >
        Regístrate en Fingo y{' '}
        <Typography
          color="primary"
          fontSize={{ mobile: 30, desktop: 34 }}
          textAlign={{ mobile: 'center', desktop: 'left' }}
          fontWeight={700}
          lineHeight="120%"
          width={{ mobile: '100%', desktop: '80%' }}
        >
          ¡Accede a financiamiento HOY!
        </Typography>
      </Typography>
      <Typography
        color="#4E4E4E"
        variant="subtitle2v2"
        textAlign={{ mobile: 'center', desktop: 'left' }}
        width={{ tablet: '60%', desktop: '90%' }}
        my={{ mobile: 0, desktop: 2 }}
      >
        ¿Necesitas liquidez?
        {isTablet
          && ', No sigas esperando, regístrate y anticipa el pago de tus facturas hoy'}
      </Typography>
    </>
  );
};

export default SimulatorRegisterVideoLandingTitle;
