import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import LazyLoadImage from '@fingo/lib/components/images/LazyLoadImage';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthPaper from '../../../components/papers/AuthPaper';
import PromoOperationLandingTitle from '../components/titles/PromoOperationLandingTitle';
import PromoOperationLandingCodeBox from '../components/boxes/PromoOperationLandingCodeBox';
import FeatureList from '../../../components/lists/FeatureList';
import { FEATURES } from '../constants/promo-operation-landing';
import PromoOperation from '../assets/PromoOperation';
import LandingBackground from '../components/layout/LandingBackground';
import Background from '../assets/Background';
import MobileBackground from '../assets/MobileBackground';
import ContactFooter from '../components/footers/ContactFooter';
import FingoLogo from '../assets/FingoLogo';
import MobileFingoLogo from '../assets/MobileFingoLogo';

const PromoOperationLanding = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isGoogleCampaign = params.get('operation') === 'google';

  const isDesktop = useMediaQuery(
    (theme) => theme.breakpoints.up('desktop') && theme.breakpoints.down('lg'),
  );
  const isTablet = useIsMobile('desktop');
  const isMobile = useIsMobile('tablet');

  return (
    <LandingBackground
      backgroundImageUrl={isTablet ? MobileBackground : Background}
      ribbonText="por tiempo limitado"
    >
      <Stack
        direction={{ mobile: 'column-reverse', desktop: 'row' }}
        height={{ desktop: 730 }}
        mt={{ mobile: 2, tablet: 4, desktop: 0 }}
        spacing={{ mobile: 3, desktop: 0 }}
        width="100%"
      >
        <AuthPaper
          logo={isTablet ? MobileFingoLogo : FingoLogo}
          elevation={isTablet ? 4 : 0}
          height={{ desktop: 680 }}
          width={{ mobile: 350, tablet: 400, desktop: 500 }}
          mx={{ mobile: 'auto', desktop: 0 }}
          landingTag="Promo-Landing"
        />
        <Stack pl={{ desktop: 2, lg: 7 }} pt={{ desktop: 3 }}>
          {isTablet && (
            <LazyLoadImage
              src={PromoOperation}
              alt="Promo Operation"
              width={!isMobile ? 480 : 350}
              height={!isMobile ? 320 : 240}
            />
          )}
          <PromoOperationLandingTitle />
          {!isTablet && (
            <LazyLoadImage
              src={PromoOperation}
              alt="Promo Operation"
              width={isDesktop ? 650 : 800}
              height={450}
            />
          )}
          {isGoogleCampaign && (
            <Box
              alignSelf={{ desktop: 'flex-end' }}
              mt={{ desktop: -7 }}
              mr={{ desktop: '10%', lg: 2 }}
            >
              <PromoOperationLandingCodeBox />
            </Box>
          )}
        </Stack>
      </Stack>
      <Stack
        pb={15}
        pt={6}
        alignItems="center"
        justifyContent="space-around"
        height={{ mobile: 600, tablet: 700 }}
      >
        <LazyLoadImage
          src={isTablet ? MobileFingoLogo : FingoLogo}
          alt="Fingo Logo"
          height={120}
          width={isTablet ? 200 : 350}
        />
        <Box component={Stack} alignItems="center">
          <Typography variant="subtitle3v2" color="#625c60" textAlign="center">
            Únete hoy a las +1000 empresas
          </Typography>
          <Typography variant="subtitle3v2">
            que{' '}
            <Typography variant="subtitle3v2" component="span" color="primary">
              confían en fingo
            </Typography>
          </Typography>
        </Box>
        <FeatureList features={FEATURES} width={{ tablet: '80%' }} />
      </Stack>
      <ContactFooter />
    </LandingBackground>
  );
};

export default PromoOperationLanding;
