import { gql } from '@apollo/client';

const UPDATE_LOKAL_DEBTOR = gql`
  mutation NotifyLokalCredential($nationalIdentifier: String!) {
    notifyLokalCredentials(nationalIdentifier: $nationalIdentifier) {
      success
    }
  }
`;

export default UPDATE_LOKAL_DEBTOR;
