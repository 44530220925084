import React from 'react';
import { Link } from '@mui/material';
import { INSTAGRAM_LINK } from '../../constants/links';
import InstagramIcon from '../icons/InstagramIcon';

const InstagramLink = () => (
  <Link
    aria-label="Instagram blank link"
    href={INSTAGRAM_LINK}
    target="_blank"
    rel="noopener noreferrer"
  >
    <InstagramIcon />
  </Link>
);

export default InstagramLink;
