/* eslint-disable import/prefer-default-export */
import React from 'react';
import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import Face2OutlinedIcon from '@mui/icons-material/Face2Outlined';

export const FEATURES = [
  {
    id: 1,
    title: 'Rápido',
    subtitle: 'en un día',
    icon: <PunchClockOutlinedIcon />,
  },
  {
    id: 2,
    title: 'Conveniente',
    subtitle: 'las tasas más bajas',
    icon: <AutoGraphOutlinedIcon />,
  },
  {
    id: 3,
    title: 'Sin papeleo',
    subtitle: '100% digital',
    icon: <ComputerOutlinedIcon />,
  },
  {
    id: 4,
    title: 'Amigable',
    subtitle: 'de fácil uso',
    icon: <Face2OutlinedIcon />,
  },
];
