import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import LazyLoadImage from '@fingo/lib/components/images/LazyLoadImage';

const LogoLink = ({ logo }) => (
  <Link href="/" underline="none" rel="noopener noreferrer">
    <LazyLoadImage src={logo} alt="logo" width={100} height={40} />
  </Link>
);

LogoLink.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default LogoLink;
