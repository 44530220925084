import React from 'react';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

const PromoOperationLandingCodeBox = () => (
  <Stack
    direction={{ mobile: 'column', tablet: 'row' }}
    alignItems="center"
    justifyContent="center"
    spacing={1}
  >
    <Typography
      color="#4E4E4E"
      variant="subtitle2v2"
      width={{ mobile: '65%', tablet: 'inherit' }}
      textAlign={{ mobile: 'center', desktop: 'justify' }}
    >
      Oferta válida mostrando a tu ejecutivo el código:
    </Typography>
    <Typography
      color="#FFF"
      variant="buttonTextv2"
      ml={{ desktop: 2.5 }}
      sx={{
        backgroundColor: '#DA104D',
        px: 2,
        py: { mobile: 0.5, desktop: 1 },
        borderRadius: 100,
      }}
    >
      FNG099
    </Typography>
  </Stack>
);

export default PromoOperationLandingCodeBox;
