import React from 'react';
import { Link } from '@mui/material';
import { WHATS_APP_LINK } from '../../constants/links';
import WhatsAppIcon from '../icons/WhatsAppIcon';

const WhatsAppLink = () => (
  <Link
    aria-label="WhatsApp blank link"
    href={WHATS_APP_LINK}
    target="_blank"
    rel="noopener noreferrer"
  >
    <WhatsAppIcon />
  </Link>
);

export default WhatsAppLink;
