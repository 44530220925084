import React, { useCallback, useState } from 'react';
import useTextFieldInput from '@fingo/lib/hooks/useTextFieldInput';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import InvoiceSimulatorVariables from '../../../components/pilots/components/InvoiceSimulatorVariables';
import SimulationAmounts from '../../../components/simulator/SimulationAmounts';

const BOUNCE_ANIMATION = {
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(10px)',
    },
  },
  animation: 'bounce 3s infinite',
};

const FactoringSimulation = () => {
  const [amount, _setAmount] = useState(10000000);
  const [daysToPay, setDaysToPay] = useTextFieldInput(30);
  const [client, setClient] = useState();
  const [debtor, setDebtor] = useState();

  const scrollToBottom = useCallback(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Stack
      alignItems="center"
      sx={{
        background: 'linear-gradient(180deg, #FF0061 49.89%, #6F28A9 100%)',
      }}
      mt={{ mobile: -13, desktop: 0 }}
      pt={{ mobile: 17, desktop: 1 }}
      zIndex={1}
      pb={2}
    >
      <Typography
        color="#FFF"
        fontSize={24}
        fontWeight={700}
        textAlign="center"
        width={{ mobile: '70%', desktop: '100%' }}
        sx={{
          textTransform: 'uppercase',
          cursor: 'pointer',
          ...BOUNCE_ANIMATION,
        }}
        onClick={scrollToBottom}
      >
        simula tu financiamiento aquí
      </Typography>
      <ExpandCircleDownOutlinedIcon
        fontSize="large"
        sx={{ color: '#FFF', cursor: 'pointer', ...BOUNCE_ANIMATION }}
        onClick={scrollToBottom}
      />
      <Paper
        elevation={3}
        component={Stack}
        direction="column"
        p={2}
        mt={3}
        maxWidth={{ mobile: 355, tablet: 600, desktop: 770 }}
      >
        <InvoiceSimulatorVariables
          setClient={setClient}
          setDebtor={setDebtor}
          amount={amount}
          _setAmount={_setAmount}
          daysToPay={daysToPay}
          _setDaysToPay={setDaysToPay}
          showSliders={false}
          elevation={0}
        />
        <SimulationAmounts
          client={client}
          debtor={debtor}
          amount={amount}
          days={daysToPay}
          showRegisterButton={false}
          elevation={0}
        />
      </Paper>
    </Stack>
  );
};

export default FactoringSimulation;
