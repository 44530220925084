import React from 'react';
import { Redirect } from 'react-router-dom';
import useAuthParams from '../hooks/useAuthParams';
import PartnerLoginHandler from '../components/PartnerLoginHandler';
import LOGIN_CONFIGURATIONS from '../constants/external-login-landing';

const ExternalLoginLanding = () => {
  const { redirect, from, params } = useAuthParams();

  if (redirect) return <Redirect to="/" />;

  const config = LOGIN_CONFIGURATIONS[from];
  if (!config) return null;

  return (
    <PartnerLoginHandler
      mutation={config.mutation}
      getVariables={config.getVariables}
      onCompleted={config.onCompleted}
      validateParams={config.validateParams}
      params={params}
      from={from}
    />
  );
};

export default ExternalLoginLanding;
