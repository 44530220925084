import { logOut } from '@fingo/lib/apollo/helpers/auth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useAuthParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  logOut();

  const token = params.get('token');
  if (token) {
    params.set('token', token.replaceAll(' ', '+'));
  }

  const from = params.get('from');
  const redirect = !from || !params.toString();

  return { redirect, from, params };
};

export default useAuthParams;
