import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';

const TextRibbon = ({ text }) => (
  <Box
    component={Stack}
    justifyContent="center"
    alignItems="center"
    width={{ mobile: 700, desktop: 610, lg: 760 }}
    height={{ mobile: 30, desktop: 45, lg: 50 }}
    pl={{ mobile: 20, desktop: 13, lg: 22 }}
    position="absolute"
    top={0}
    right={{ mobile: -200, desktop: -180 }}
    zIndex={1}
    sx={{
      background: 'linear-gradient(265deg, #FF0061 36.01%, #6F28A9 73.25%)',
      transform: 'rotate(29deg)',
    }}
  >
    <Typography
      fontWeight={600}
      fontSize={{ mobile: 14, desktop: 16, lg: 20 }}
      lineHeight={{ desktop: '25px' }}
      letterSpacing={{ mobile: -0.5, desktop: -1 }}
      textTransform="uppercase"
      textAlign="center"
      color="common.white"
    >
      {text}
    </Typography>
  </Box>
);

TextRibbon.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextRibbon;
