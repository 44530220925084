import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TextRibbon from '../ribbons/TextRibbon';

const LandingBackground = ({
  children,
  backgroundImageUrl,
  ribbonText = null,
  ...props
}) => (
  <Stack
    id="landing-background"
    width="100%"
    overflow="hidden"
    sx={{
      position: 'relative',
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
    {...props}
  >
    {ribbonText && <TextRibbon text={ribbonText} />}
    {children}
  </Stack>
);

LandingBackground.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundImageUrl: PropTypes.string.isRequired,
  ribbonText: PropTypes.string,
};

LandingBackground.defaultProps = {
  ribbonText: null,
};

export default LandingBackground;
