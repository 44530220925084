import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import FingoCircularProgressLoader from '@fingo/lib/components/loader/FingoCircularProgressLoader';
import Stack from '@mui/material/Stack';

const PartnerLoginHandler = ({
  mutation,
  onCompleted,
  getVariables,
  validateParams,
  params,
  from,
}) => {
  const history = useHistory();

  const [executeLogin] = useMutation(mutation, {
    variables: getVariables(params),
    onCompleted: (data) => {
      onCompleted(data, history, params);
    },
    onError: () => {
      history.replace(`/partner-login?partner=${from}`);
    },
  });

  useEffect(() => {
    if (!validateParams(params)) {
      history.replace(`/partner-login?partner=${from}`);
      return;
    }
    executeLogin();
  }, [executeLogin, params, history, validateParams, from]);

  return (
    <Stack
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <FingoCircularProgressLoader />
    </Stack>
  );
};

PartnerLoginHandler.propTypes = {
  mutation: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  getVariables: PropTypes.func.isRequired,
  validateParams: PropTypes.func.isRequired,
  params: PropTypes.instanceOf(URLSearchParams).isRequired,
  from: PropTypes.string.isRequired,
};

export default PartnerLoginHandler;
