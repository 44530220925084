import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LazyLoadImage from '@fingo/lib/components/images/LazyLoadImage';

const PartnerBox = ({ logo, partner, ...props }) => (
  <Box
    component={Stack}
    direction="row"
    alignItems="center"
    spacing={2}
    {...props}
  >
    <Typography
      fontSize={11}
      fontWeight={400}
      color="black"
      textTransform="uppercase"
    >
      partner de
    </Typography>
    <LazyLoadImage src={logo} alt={partner} width={165} height={40} />
  </Box>
);

PartnerBox.propTypes = {
  logo: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
};

export default PartnerBox;
