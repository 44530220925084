import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const PromoOperationLandingTitle = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isChurnCampaign = params.get('operation') === 'next';

  return (
    <Stack alignItems={{ mobile: 'center', desktop: 'flex-start' }}>
      <Typography
        textAlign={{ mobile: 'center', desktop: 'justify' }}
        color="primary"
        variant="h1v2"
      >
        ¡Adelanta el pago de tus facturas!
      </Typography>
      <Typography
        textAlign={{ mobile: 'center', desktop: 'justify' }}
        width={{ mobile: '70%', lg: 'inherit' }}
        color="#4D4D4D"
        variant="h2v2"
      >
        Tu {isChurnCampaign ? 'próxima' : 'primera'} operación a una tasa
        imperdible
      </Typography>
      <Typography
        textAlign={{ mobile: 'center', desktop: 'justify' }}
        color="primary"
        variant="h3v2"
      >
        0,99%
      </Typography>
      <Typography
        textAlign={{ mobile: 'center', desktop: 'justify' }}
        color="#000"
        variant="subtitle1v2"
      >
        (hasta $20.000.000)
      </Typography>
    </Stack>
  );
};

export default PromoOperationLandingTitle;
