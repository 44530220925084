import React from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import LazyLoadImage from '@fingo/lib/components/images/LazyLoadImage';
import SimulatorRegisterVideoLandingTitle from '../components/titles/SimulatorRegisterVideoLandingTitle';
import FactoringSimulation from '../components/FactoringSimulation';
import SimulatorRegisterVideo from '../components/videos/SimulatorRegisterVideo';
import AuthPaper from '../../../components/papers/AuthPaper';
import LandingBackground from '../components/layout/LandingBackground';
import MobileBackground from '../assets/MobileBackground';
import Background from '../assets/Background';
import MobileFingoLogo from '../assets/MobileFingoLogo';
import FingoLogo from '../assets/FingoLogo';
import Home from '../assets/Home';

const SimulatorRegisterVideoLanding = () => {
  const isTablet = useIsMobile('desktop');

  return (
    <LandingBackground
      backgroundImageUrl={isTablet ? MobileBackground : Background}
    >
      <Stack
        direction={{ mobile: 'column-reverse', desktop: 'row' }}
        height={{ desktop: 625 }}
        mt={{ mobile: 2, tablet: 4, desktop: 0 }}
        spacing={{ mobile: 4, desktop: 0 }}
      >
        <AuthPaper
          logo={isTablet ? MobileFingoLogo : FingoLogo}
          elevation={isTablet ? 4 : 0}
          width={{ mobile: 350, tablet: 400, desktop: 525 }}
          mx={{ mobile: 'auto', desktop: 0 }}
          zIndex={{ mobile: 2, desktop: 0 }}
          landingTag="Video-Landing"
        />
        <Stack
          pt={{ mobile: 0, desktop: 6 }}
          pl={{ desktop: 4, lg: 10 }}
          direction={{ mobile: 'column-reverse', desktop: 'column' }}
          alignItems={{ mobile: 'center', desktop: 'flex-start' }}
        >
          <SimulatorRegisterVideoLandingTitle />
          <Stack
            alignSelf="flex-start"
            direction="row"
            width="fit-content"
            pl={{ mobile: 2, tablet: 20, desktop: 0, lg: 7 }}
            mb={{ mobile: 10, tablet: 12, desktop: 0 }}
          >
            <LazyLoadImage
              src={Home}
              alt="Home"
              width={{ mobile: 253, tablet: 400, desktop: 525 }}
              height={{ mobile: 145, tablet: 235, desktop: 325 }}
            />
            <Box
              position="absolute"
              top={{ mobile: 80, tablet: 145, desktop: 368 }}
              left={{ mobile: '32%', tablet: '46%', desktop: '57%', lg: '60%' }}
              width={{ mobile: 238, tablet: 300, desktop: 370 }}
              height={{ mobile: 134, tablet: 169, desktop: 208 }}
              zIndex={2}
              boxShadow={3}
              bgcolor="black"
              overflow="hidden"
              borderRadius={2}
            >
              <SimulatorRegisterVideo />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <FactoringSimulation />
    </LandingBackground>
  );
};
export default SimulatorRegisterVideoLanding;
