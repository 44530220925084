import { logIn } from '@fingo/lib/apollo/helpers/auth';
import setSelectedCompanyRutFromExternalLogin from '@fingo/lib/apollo/reactive-variables/localUpdates';
import {
  ACEPTA_LOGIN_SESSION_ID,
  SENEGOCIA_AUTH,
} from '../../../graphql/mutations';

const LOGIN_CONFIGURATIONS = {
  acepta: {
    mutation: ACEPTA_LOGIN_SESSION_ID,
    getVariables: (params) => ({
      buttonFlag: params.get('button'),
      sessionId: params.get('session_id'),
      clientRut: parseInt(params.get('client_rut'), 10),
      companyRut: parseInt(params.get('company_rut'), 10),
    }),
    validateParams: (params) => params.has('button') && params.has('session_id'),
    onCompleted: (data, history) => {
      const { aceptaAuthSessionId } = data;
      logIn(aceptaAuthSessionId);
      setSelectedCompanyRutFromExternalLogin(aceptaAuthSessionId);
      history.push({ pathname: '/app' }, { partner: 'acepta' });
    },
  },
  senegocia: {
    mutation: SENEGOCIA_AUTH,
    getVariables: (params) => ({
      token: params.get('token'),
      isOrdering: params.get('button') === 'button_ordering',
      loginFlag: params.get('button'),
    }),
    validateParams: (params) => params.has('token') && params.has('button'),
    onCompleted: (data, history, params) => {
      const { senegociaAuth } = data;
      const purchaseOrderId = params.get('purchase_order_id');
      const button = params.get('button');

      setSelectedCompanyRutFromExternalLogin({
        actualCompanyRut: Number(params.get('company_rut').split('-')[0]),
      });
      logIn(senegociaAuth);

      if (button === 'button_ordering') {
        const searchString = purchaseOrderId
          ? `purchaseOrderId=${purchaseOrderId}`
          : '';
        history.replace(`/app/sales/ordering/available?${searchString}`);
      } else {
        history.replace('/app/sales/factoring');
      }
    },
  },
};

export default LOGIN_CONFIGURATIONS;
