import React from 'react';
import Instagram from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';

const InstagramIcon = () => (
  <IconButton id="instagram-icon-button" aria-label="Instagram IconButton">
    <Box
      sx={{
        background:
          'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Instagram sx={{ color: 'white', fontSize: 20 }} />
    </Box>
  </IconButton>
);

export default InstagramIcon;
