import React from 'react';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import WhatsAppLink from '../links/WhatsAppLink';
import LinkedInLink from '../links/LinkedInLink';
import InstagramLink from '../links/InstagramLink';
import LogoLink from '../links/LogoLink';
import MapsLink from '../links/MapsLink';
import FingoLogo from '../../assets/FingoLogo';
import MobileFingoLogo from '../../assets/MobileFingoLogo';

const CONTACTS = [
  {
    id: 'comercial',
    area: 'Comercial',
    phone: '+56 9 6246 4446',
    email: 'contacto@fingo.cl',
  },
  {
    id: 'collection',
    area: 'Cobranza',
    phone: '+56 9 9434 1225',
    email: 'cobranza_ratificacion@fingo.cl',
  },
];

const ContactFooter = () => {
  const isMobile = useIsMobile('tablet');
  const { t } = useTranslation();
  return (
    <Stack
      width="100%"
      direction={{ mobile: 'column', tablet: 'row' }}
      spacing={1.5}
      justifyContent="space-around"
      backgroundColor="#F8F8F8"
      p={4}
    >
      <Typography
        variant="footerTitle"
        display={{ mobile: 'block', tablet: 'none', desktop: 'block' }}
        my={{ tablet: 'auto' }}
      >
        Contáctanos
      </Typography>
      {CONTACTS.map(({ id, area, phone, email }) => (
        <Stack key={id} spacing={{ mobile: 1, tablet: 2 }}>
          <Typography variant="footerSubtitle">{area}</Typography>
          <Typography variant="footerText">
            {isMobile ? `${phone} / ${email}` : phone}
          </Typography>
          {!isMobile && <Typography variant="footerText">{email}</Typography>}
        </Stack>
      ))}
      <Stack spacing={{ mobile: 1, tablet: 2 }}>
        <Typography variant="footerSubtitle">Dirección</Typography>
        <Typography variant="footerText">
          {t('Direction')}
        </Typography>
        <MapsLink variant="footerText" />
      </Stack>
      <Stack
        direction={{ mobile: 'row', tablet: 'column' }}
        spacing={1}
        alignItems="center"
      >
        <Typography variant="footerSubtitle">¡Síguenos!</Typography>
        <Stack direction="row" alignItems="center">
          <LinkedInLink />
          <InstagramLink />
          <WhatsAppLink />
        </Stack>
        <LogoLink logo={isMobile ? MobileFingoLogo : FingoLogo} />
      </Stack>
    </Stack>
  );
};

export default ContactFooter;
