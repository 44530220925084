import React from 'react';
import WhatsApp from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';

const WhatsAppIcon = () => (
  <IconButton
    id="whats-app-icon-button"
    aria-label="WhatsApp IconButton"
    sx={{ color: '#25D366' }}
  >
    <WhatsApp fontSize="40px" color="common.white" padding="8px" />
  </IconButton>
);

export default WhatsAppIcon;
