import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const PartnerLoginLandingTitle = ({ partner }) => (
  <Stack alignItems={{ mobile: 'center', desktop: 'flex-start' }} spacing={{ mobile: 1, desktop: 0 }}>
    <Typography
      color="#000"
      fontSize={{ mobile: 25, desktop: 28 }}
      textAlign={{ mobile: 'center', desktop: 'left' }}
      fontWeight={500}
      lineHeight="120%"
      width={{ mobile: '90%', desktop: '80%' }}
    >
      Regístrate en Fingo y{' '}
    </Typography>
    <Typography
      color="primary"
      fontSize={{ mobile: 30, desktop: 34 }}
      textAlign={{ mobile: 'center', desktop: 'left' }}
      fontWeight={700}
      lineHeight="120%"
      width={{ mobile: '90%', desktop: '80%' }}
    >
      ¡Accede a financiamiento HOY!
    </Typography>
    <Typography
      color="#4E4E4E"
      variant="subtitle2v2"
      textAlign={{ mobile: 'center', desktop: 'left' }}
      width={{ mobile: '70%', tablet: '60%', desktop: '90%' }}
    >
      Aproveha y consigue financiamiento de manera más{' '}
      <Typography component="span" color="primary" variant="subtitle2v2">
        rápida
      </Typography>{' '}
      y{' '}
      <Typography component="span" color="primary" variant="subtitle2v2">
        fácil
      </Typography>{' '}
      al ser{' '}
      <Typography component="span" color="primary" variant="subtitle2v2">
        cliente {partner || 'Fingo'}
      </Typography>
    </Typography>
  </Stack>
);

PartnerLoginLandingTitle.propTypes = {
  partner: PropTypes.string,
};

PartnerLoginLandingTitle.defaultProps = {
  partner: null,
};

export default PartnerLoginLandingTitle;
