import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Stack from '@mui/material/Stack';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import LazyLoadImage from '@fingo/lib/components/images/LazyLoadImage';
import FactoringSimulation from '../components/FactoringSimulation';
import AuthPaper from '../../../components/papers/AuthPaper';
import LandingBackground from '../components/layout/LandingBackground';
import MobileBackground from '../assets/MobileBackground';
import Background from '../assets/Background';
import MobileFingoLogo from '../assets/MobileFingoLogo';
import FingoLogo from '../assets/FingoLogo';
import PartnerLoginLandingTitle from '../components/titles/PartnerLoginLandingTitle';
import Home from '../assets/Home';
import PartnerBox from '../components/boxes/PartnerBox';
import Acepta from '../assets/Acepta';
import Senegocia from '../assets/Senegocia';

const PARTNER_TO_LOGO = {
  acepta: Acepta,
  senegocia: Senegocia,
};

const PartnerLoginLanding = () => {
  const isTablet = useIsMobile('desktop');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const partner = params.get('partner');

  return (
    <LandingBackground
      backgroundImageUrl={isTablet ? MobileBackground : Background}
    >
      <Stack
        direction={{ mobile: 'column-reverse', desktop: 'row' }}
        minHeight={{ desktop: 625 }}
        mt={{ mobile: 8, tablet: 4, desktop: 0 }}
        spacing={{ mobile: 6, desktop: 0 }}
      >
        <Stack bgcolor={{ mobile: 'transparent', desktop: 'white' }}>
          {!!partner && !isTablet && (
            <PartnerBox
              logo={PARTNER_TO_LOGO[partner]}
              partner={partner}
              mt={4}
              ml={4}
            />
          )}
          <AuthPaper
            flexGrow={1}
            logo={isTablet ? MobileFingoLogo : FingoLogo}
            elevation={isTablet ? 4 : 0}
            width={{ mobile: 350, tablet: 400, desktop: 525 }}
            mx={{ mobile: 'auto', desktop: 0 }}
            zIndex={{ mobile: 2, desktop: 0 }}
            py={2}
          />
        </Stack>
        <Stack
          pt={{ mobile: 0, desktop: 6 }}
          pl={{ desktop: 2, lg: 8 }}
          direction={{ mobile: 'column-reverse', desktop: 'column' }}
          alignItems={{ mobile: 'center', desktop: 'flex-start' }}
        >
          <PartnerLoginLandingTitle partner={partner} />
          {!!partner && isTablet && (
            <PartnerBox
              logo={PARTNER_TO_LOGO[partner]}
              partner={partner}
              my={3}
            />
          )}
          <LazyLoadImage
            src={Home}
            alt="Home"
            width={{ mobile: 253, tablet: 400, desktop: 480, lg: 600 }}
            height={{ mobile: 145, tablet: 235, desktop: 280, lg: 350 }}
          />
        </Stack>
      </Stack>
      <FactoringSimulation />
    </LandingBackground>
  );
};
export default PartnerLoginLanding;
