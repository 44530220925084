import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { MAPS_LINK } from '../../constants/links';

const MapsLink = ({ ...props }) => (
  <Typography {...props}>
    <Link
      aria-label="Maps blank link"
      href={MAPS_LINK}
      sx={{ textDecoration: 'none', color: 'text.primary' }}
      rel="noopener noreferrer"
      target="_blank"
      underline="hover"
    >
      Ver en Maps
    </Link>
  </Typography>
);

export default MapsLink;
